.App {
  text-align: center;
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.footer {
  background-color: #14202e;
  color: #fff;
  padding: 2px;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}



.input-with-icon {
  position: relative;
}

.input-with-icon input[type="text"] {
  padding-right: 30px; /* Espacio para el icono */
}

.input-with-icon .icon {
  position: absolute;
  top: 50%;
  right: 10px; /* Puedes ajustar la posición del icono */
  transform: translateY(-50%);
}


